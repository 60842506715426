import { action, observable } from 'mobx';
import * as api from '../services/api';
import UserStore from './common/UserStore';
import moment from 'moment';

class OrdersKitchenStore {
    @observable delieveryOrders = [];
    @observable dineInOrders = [];
    @observable instaServiceProductions = [];
    @observable delieveryOrdersLoading = true;
    @observable dineInOrdersLoading = true;
    @observable currentWarmerStocks = [];
    deliveryOrdersLastModified = null;
    dineInOrdersLastModified = null;
    instaServiceLastModified = null;
    @observable inCartOrders = [];
    @observable inCartItems = [];

    @observable date = moment().format('DD-MM-YYYY');

    @action handleGetDeliveryOrders = (
        forceLatest = false,
        date = this.date
    ) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.deliveryOrdersLastModified;
        }
        api.getDeliveryOrders(date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.deliveryOrdersLastModified = lastModified;
                    if (!responseBody.list) {
                        //there's no delivery order
                        this.delieveryOrders = [];
                    }
                    //this array will be used to filter out completed orders from prepared/Done orders list
                    if (responseBody.list && responseBody.list.length > 0) {
                        this.delieveryOrders =
                            responseBody.list.filter(
                                (order) => order.status === 'Pending'
                            ) || [];
                    }
                } else {
                    // no change since the last call
                }
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            })
            .finally(() => {
                this.delieveryOrdersLoading = false;
            });
    };


    @action getInCartOrders = (hubCode) => {
        let items = [];
        api.getInCartOrders(hubCode)
            .then((inCartOrders) => {
                this.inCartOrders = inCartOrders
                .filter(order => Array.isArray(order.items) && order.items.length > 0)
                .map(order => ({
                    ...order,
                    items: order.items.flatMap(item => {
                        // Duplicate item based on quantity
                        return Array(item.quantity).fill().map(() => ({
                            ...item,
                            amount: 1,
                            pick_up_type: order.order_mode,
                            sale_platform: "PopMeals",
                        }));
                    }),
                }));

            this.inCartItems = this.inCartOrders.flatMap(order => order.items);
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            })
    };

    @action handleGetDineInOrders = (forceLatest = false, date = this.date) => {
        this.date = date;
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.dineInOrdersLastModified;
        }

        api.getDineInOrders(this.date, ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    const { list: dineInOrders, message } = responseBody;
                    this.dineInOrdersLastModified = lastModified;
                    const newDineInOrders = dineInOrders
                        ? dineInOrders.filter(
                              (order) =>
                                  order.status === 'Pending' &&
                                  order.items &&
                                  order.items.length > 0
                          )
                        : [];

                    const sortedDineInOrders =
                        newDineInOrders &&
                        newDineInOrders.sort(
                            (a, b) => a.order_date - b.order_date
                        );

                    this.dineInOrders = sortedDineInOrders
                        ? [...sortedDineInOrders]
                        : [];
                }
            })
            .catch((err) => {
                UserStore.message = err.message;
            })
            .finally(() => {
                this.dineInOrdersLoading = false;
            });
    };

    @action handleGetInstaServiceProductions = (
        forceLatest = false
    ) => {
        let ifModifiedSince = null;
        if (!forceLatest) {
            ifModifiedSince = this.instaserviceLastModified;
        }
        api.getInstaServiceProduction(ifModifiedSince)
            .then((res) => {
                if (res.modified) {
                    const { lastModified, responseBody } = res;
                    this.instaserviceLastModified = lastModified;
                    this.instaServiceProductions = responseBody.list ? responseBody.list : [];
                    this.instaServiceLastModified = lastModified;
                    if (responseBody.current_stock) {
                        this.currentWarmerStocks = responseBody.current_stock;
                    }
                } else {
                    // no change since the last call
                }
            })
            .catch((err) => {
                console.log(err);
                UserStore.message = err.message;
            })
    };
}

const store = new OrdersKitchenStore();
export default store;
