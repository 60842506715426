import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from "react-helmet";
import Select from 'react-select';
import Loading from '../Loading';
import qs from 'qs';
import { reaction } from 'mobx';
import Cookies from 'js-cookie';

const base = 'operation-dashboard';

@inject('OperationDashboardStore')
@observer
class OperationDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAreas: [],
            selectedBusinessManagers: [],
            selectedTerritoryManagers: [],
            selectedOutlets: [],
            filteredOutlets: [],
            rowOffsets: [],
            currentRowIndex: 0,
        };
        this.refreshInterval = null;
        this.autoScrollInterval = null;
    }

    componentDidMount() {
        const parameters = qs.parse(this.props.location.search.slice(1));
        const { token } = parameters;
        const {
            OperationDashboardStore: {
                handleGetAreaByOutletData,
                handleGetOperationDashboardInfo
            }
        } = this.props;
        if (token) {
            Cookies.set('token', token);
        }
        handleGetAreaByOutletData();

        this.refreshInterval = setInterval(() => {
            handleGetOperationDashboardInfo(this.state.filteredOutlets);
        }, 1000 * 60);

        setTimeout(() => {
            this.autoScrollInterval = setInterval(this.handleAutoScroll, 1000 * 10);
        }, 0);

        this.disposeLoadingReaction = reaction(
            () => this.props.OperationDashboardStore.dashboardInfoLoading,
            (loadingNow, previousLoading) => {
            if (!loadingNow && previousLoading) {
                setTimeout(() => this.measureRowOffsets(), 0);
            }
            }
        );
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.autoScrollInterval);
        if (this.disposeLoadingReaction) this.disposeLoadingReaction();
    }

    handleFilterChange = (filterName, selectedOptions) => {
        this.setState({
            [filterName]: selectedOptions,
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.selectedAreas !== this.state.selectedAreas ||
            prevState.selectedBusinessManagers !== this.state.selectedBusinessManagers ||
            prevState.selectedTerritoryManagers !== this.state.selectedTerritoryManagers ||
            prevState.selectedOutlets !== this.state.selectedOutlets
        ) {
            this.filterStores();
        }
    }

    // ---- Auto-scroll logic ----

    measureRowOffsets = () => {
        // Find all card elements
        const cards = document.querySelectorAll(`.${base}__card`);
        if (!cards.length) return;

        // Sort by vertical offset
        const cardPositions = Array.from(cards)
            .map(card => {
                const rect = card.getBoundingClientRect();
                const top = rect.top + window.scrollY;
                return { card, top };
            })
            .sort((a, b) => a.top - b.top);

        // Group cards by “row” using a small threshold
        const rowOffsets = [];
        let currentRowTop = null;
        const threshold = 10; // tolerance (pixels) for grouping

        cardPositions.forEach(({ top }) => {
            if (currentRowTop === null || Math.abs(top - currentRowTop) > threshold) {
                // We’ve encountered a new row
                rowOffsets.push(top);
                currentRowTop = top;
            }
            // Else: it’s in the same row, so do nothing
        });

        this.setState({
            rowOffsets,
            currentRowIndex: 0
        });
    }

    handleAutoScroll = () => {
        const { rowOffsets, currentRowIndex } = this.state;
        if (!rowOffsets.length) return;

        // If we pass the last row, go back to the top (+20px padding)
        if (currentRowIndex >= rowOffsets.length) {
            window.scrollTo({ top: 20, behavior: 'smooth' });
            this.setState({ currentRowIndex: 0 });
            return;
        }

        // Otherwise scroll to the next row offset, minus a small margin
        const targetOffset = rowOffsets[currentRowIndex] - 20;

        // Don’t scroll beyond the bottom
        const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
        const finalOffset = Math.min(targetOffset, maxScroll);

        window.scrollTo({ top: finalOffset, behavior: 'smooth' });
        this.setState({ currentRowIndex: currentRowIndex + 1 });
    }

    filterStores = () => {
        const { OperationDashboardStore } = this.props;
        const { areas, areaOutletMap, businessManagers, businessManagerOutletMap, 
            territoryManagers, territoryManagerOutletMap, outlets, handleGetOperationDashboardInfo } = OperationDashboardStore;
        // Get selected values
        let selectedAreas = this.state.selectedAreas.map((area) => area.value);
        if (selectedAreas.includes("All")) {
            selectedAreas = areas.map((area) => area.value);
        }
        const areaOutlets = selectedAreas.flatMap((area) => areaOutletMap[area] || []);

        let selectedBusinessManagers = this.state.selectedBusinessManagers.map((manager) => manager.value);
        if (selectedBusinessManagers.includes("All")) {
            selectedBusinessManagers = businessManagers.map((manager) => manager.value);
        }
        const businessManagerOutlets = selectedBusinessManagers.flatMap((manager) => businessManagerOutletMap[manager] || []);

        let selectedTerritoryManagers = this.state.selectedTerritoryManagers.map((manager) => manager.value);
        if (selectedTerritoryManagers.includes("All")) {
            selectedTerritoryManagers = territoryManagers.map((manager) => manager.value);
        }
        const territoryManagerOutlets = selectedTerritoryManagers.flatMap((manager) => territoryManagerOutletMap[manager] || []);

        let selectedOutlets = this.state.selectedOutlets.map((outlet) => outlet.value);
        if (selectedOutlets.includes("All")) {
            selectedOutlets = outlets.map((outlet) => outlet.value);
        }
        const outletValues = outlets.map((outlet) => outlet.value);

        const filteredOutlets = [
            ...(new Set(outletValues.filter(outlet =>
                (!selectedAreas.length || areaOutlets.includes(outlet)) &&
                (!selectedBusinessManagers.length || businessManagerOutlets.includes(outlet)) &&
                (!selectedTerritoryManagers.length || territoryManagerOutlets.includes(outlet)) &&
                (!selectedOutlets.length || selectedOutlets.includes(outlet))
            )))
        ];

        // Fetch the data for these outlets
        handleGetOperationDashboardInfo(filteredOutlets);
        this.setState({ filteredOutlets }, () => {
            // After new data finishes rendering new cards,
            // re-measure the row offsets so auto-scroll targets are up-to-date.
            setTimeout(() => this.measureRowOffsets(), 0);
        });
    };

    render() {
        const { OperationDashboardStore } = this.props;

        const {
            closedPopmealsStores,
            closedVirtualBrands,
            areas,
            businessManagers,
            territoryManagers,
            outlets,
            noOutletManagerClockedIn,
            allIpadsOffline,
            outletWithLessMenuItem,
            outletWithTopFiveOOS,
            receivingNotDone,
            cardTerminalNotConnected,
            noSalesInTwoHours,
            loading,
            dashboardInfoLoading
        } = OperationDashboardStore;

        if (loading) {
            return <Loading />
        }

        const {
            selectedAreas,
            selectedBusinessManagers,
            selectedTerritoryManagers,
            selectedOutlets,
            filteredOutlets
        } = this.state;

        const filterApplied = Object.keys(this.state.selectedAreas).length > 0 ||
                Object.keys(this.state.selectedBusinessManagers).length > 0 ||
                Object.keys(this.state.selectedTerritoryManagers).length > 0 ||
                Object.keys(this.state.selectedOutlets).length > 0;

        return (
            <>
                <Helmet>
                    <title>Operation Dashboard | Outlet OS | Pop Meals</title>
                </Helmet>
                <div>
                    <div className={`${base}__filters`}>
                        <Select
                            isMulti
                            options={areas.slice()}
                            value={selectedAreas}
                            onChange={(selected) => this.handleFilterChange('selectedAreas', selected)}
                            placeholder="Select Areas"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={businessManagers.slice()}
                            value={selectedBusinessManagers}
                            onChange={(selected) => this.handleFilterChange('selectedBusinessManagers', selected)}
                            placeholder="Select Business Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={territoryManagers.slice()}
                            value={selectedTerritoryManagers}
                            onChange={(selected) => this.handleFilterChange('selectedTerritoryManagers', selected)}
                            placeholder="Select Territory Managers"
                            className={`${base}__filter`}
                        />
                        <Select
                            isMulti
                            options={outlets.slice()}
                            value={selectedOutlets}
                            onChange={(selected) => this.handleFilterChange('selectedOutlets', selected)}
                            placeholder="Select Outlets"
                            className={`${base}__filter`}
                        />
                    </div>

                    {!filterApplied && (
                        <h1>
                            Select filters
                        </h1>
                    )}

                    {(filterApplied && dashboardInfoLoading) && (
                        <h1>
                            Loading...
                        </h1>
                    )}

                    {(filterApplied && !dashboardInfoLoading && filteredOutlets.length == 0) && (
                        <h1>
                            No outlet match the filters
                        </h1>
                    )}

                    {(filterApplied && !dashboardInfoLoading && filteredOutlets.length > 0) && (
                        <div className="row" style={{margin: '0px'}}> 
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px'}}>
                                    <div className={`${base}__card_header`}>Closed stores (Pop Meals)</div>
                                    {Object.keys(closedPopmealsStores || {}).length > 0 ? (
                                        <ul>
                                            {Object.entries(closedPopmealsStores).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> No closed Closed Stores (Pop Meals) found. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px'}}>
                                    <p className={`${base}__card_header`}>Closed stores (VB)</p>
                                    {Object.keys(closedVirtualBrands || {}).length > 0 ? (
                                        <ul>
                                            {Object.entries(closedVirtualBrands).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> No closed Closed Stores (VB) found. </p>
                                    )}
                                </div> 
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>No manager/ assistant manager clocked in</p>
                                    {noOutletManagerClockedIn && noOutletManagerClockedIn.length > 0 ? (
                                        <ul>
                                            {noOutletManagerClockedIn.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlet manager clocked in. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>All iPads Offline</p>
                                    {allIpadsOffline && allIpadsOffline.length > 0 ? (
                                        <ul>
                                            {allIpadsOffline.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlets have online iPads. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>Outlets with Less than 10 meals on menu</p>
                                    {outletWithLessMenuItem && outletWithLessMenuItem.length > 0 ? (
                                        <ul>
                                            {outletWithLessMenuItem.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlets have more than 10 meals on menu. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>Outlets with any of the top 5 meals OOS</p>
                                    {outletWithTopFiveOOS && outletWithTopFiveOOS.length > 0 ? (
                                        <ul>
                                            {outletWithTopFiveOOS.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All outlets have top 5 meals in stock </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>Expected receipts today not done</p>
                                    {receivingNotDone && receivingNotDone.length > 0 ? (
                                        <ul>
                                            {receivingNotDone.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All expected receipts today are done. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <p className={`${base}__card_header`}>Card terminal not connected</p>
                                    {cardTerminalNotConnected && cardTerminalNotConnected.length > 0 ? (
                                        <ul>
                                            {cardTerminalNotConnected.map((entry) => (
                                                <li style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {entry}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All card terminals connected. </p>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className={`${base}__card`} style={{marginLeft: '10px'}}>
                                    <div className={`${base}__card_header`}>No sales in past two hours</div>
                                    {Object.keys(noSalesInTwoHours || {}).length > 0 ? (
                                        <ul>
                                            {Object.entries(noSalesInTwoHours).map(([key, value]) => (
                                                <li key={key} style={{paddingRight: '6px', paddingBottom: '6px'}}>
                                                    {key} - {value.join(", ")}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p style={{margin: '15px'}}> All stores have sales in past two hours. </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
}

export default OperationDashboard;
