import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import WarmerSKU from './WarmerSKU';

const base = 'orders-kitchen';
const FIVE_MINUTES_IN_MILLISECONDS = 5 * 60 * 1000;
const FOUR_SECONDS_IN_MILLISECONDS = 5 * 1000;

@inject('ProductionStore')
@observer
class InstaServiceCard extends Component {
    state = {
        hours: '--',
        minutes: '--',
        seconds: '--',
        delay: false,
        isNewOrder: false,
        appearTimestamp: null
    };

    componentDidMount = () => {
        const { production, ProductionStore } = this.props;
        const now = moment(production.created_date, 'x');
        const then = moment();

        if (ProductionStore.lastOrderTimeStamp == null || production.created_date > ProductionStore.lastOrderTimeStamp) {
            ProductionStore.lastOrderTimeStamp = production.created_date;
            this.setState({ isNewOrder: true });
        }

        if (!this.state.appearTimestamp) {
            this.setState({ appearTimeStamp: then });
        }

        if (moment.duration(then.diff(this.state.appearTimestamp)).asMilliseconds() > FOUR_SECONDS_IN_MILLISECONDS) {
            this.setState({ isNewOrder: false });
        }

        const duration = moment.duration(then.diff(now)).asMilliseconds();
        if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
            this.setState({ delay: true, isNewOrder: false });
        }
        this.setCountdown();
    };

    componentDidUpdate = (prevProps) => {
        const { production } = this.props;
        if (production.created_date !== prevProps.production.created_date) {
            this.resetCountdown();
            this.setCountdown();
        }
    };

    componentWillUnmount() {
        this.resetCountdown();
    }

    resetCountdown = () => {
        if (this.interval) clearInterval(this.interval);
        this.setState({
            hours: '--',
            minutes: '--',
            seconds: '--',
            delay: false,
        });
    };

    setCountdown = () => {
        this.interval = setInterval(this.countdown, 1000);
    };




    countdown = () => {
        const { production, ProductionStore } = this.props;
        const now = moment(production.created_date, 'x');
        const then = moment();

        const duration = moment.duration(then.diff(now)).asMilliseconds();

        const hours = moment.utc(duration).format('H');
        const minutes = moment.utc(duration).format('mm');
        const seconds = moment.utc(duration).format('ss');

        if (duration < 0) {
            // future order.
            this.setState({
                hours,
                minutes,
                seconds,
                isNewOrder: false,
            });
        } else {
            if (this.state.appearTimestamp == null) {
                this.setState({ hours, minutes, seconds, isNewOrder: true, appearTimestamp: then });
            } else {
                if (duration > FIVE_MINUTES_IN_MILLISECONDS) {
                    this.setState({ hours, minutes, seconds, delay: true, isNewOrder: false });
                } else {
                    if (moment.duration(then.diff(this.state.appearTimestamp)).asMilliseconds() > FOUR_SECONDS_IN_MILLISECONDS) {
                        this.setState({
                            hours,
                            minutes,
                            seconds,
                            isNewOrder: false,
                        });
                    } else {
                        this.setState({
                            hours,
                            minutes,
                            seconds
                        });
                    }

                }
            }
        }
    };

    render() {
        const { production } = this.props;
        const {
            hours,
            minutes,
            seconds,
            delay: isOrderDelay,
            isNewOrder,
        } = this.state;

        return (
            <div
                className={`${base}__order_card ${base}__order_card_warmer 
                    ${ isNewOrder ? `new-order-black-yellow` : isOrderDelay ? `delay-red-black` : ``}
                `}
            >
                <div
                    className={`${base}__card_header order_header_type_warmer
                        ${ isNewOrder ? `new-order-black-yellow` : isOrderDelay ? `delay-red-black` : ``}
                    `}
                >
                    <div className={`${base}__icon_num_name`}>
                        <div
                            className={`${base}__order_number_customer_name
                                ${ isNewOrder ? `new-order-black-yellow` : isOrderDelay ? `delay-red-black` : ``}
                            `}
                        >
                            Tambah Stock
                        </div>
                    </div>

                    <p
                        className={`${base}__time_elapse
                            ${ isNewOrder ? `new-order-black-yellow` : isOrderDelay ? `delay-red-black` : ``}
                        `}
                    >
                        {`${
                            parseInt(hours) > 0 ? `${hours}:` : ``
                        }${minutes}:${seconds}`}
                    </p>
                </div>
                <div
                    className={`${base}__skus_container sku_container_warmer`}
                >
                    {production.requests &&
                    production.requests.length > 0 &&
                    production.requests.map((item) => (
                        <WarmerSKU
                            item={item}
                            key={`${item.sku}`}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default InstaServiceCard;
