import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import SalePlatformIcon from './SalePlatformIcon';
import moment from 'moment';

const base = 'cooking-v2';

@inject('ProductionStore')
@observer
class IncomingItems extends Component {
    render() {
        const { items } = this.props;

        return(
            <div>
                <div className='display--flex' style={{justifyContent: 'center'}}>
                    <div className={`${base}__incoming_tag`}>INCOMING</div>
                </div>
                {items.map((item, index) => (
                    <div key={`${item.id}-${index}`} className={`${base}__items display--flex`} style={{ backgroundColor: '#774FCF', color: 'white' }}>
                        <div className='display--flex' style={{alignItems: 'center'}}>
                            <div className='display--flex'>
                                <SalePlatformIcon
                                    key={item.id}
                                    item={item}
                                    isIncomingItems = {true}
                                />
                                <div style={{padding: '0px 7px'}}>
                                    {item.sku.length > 19 ? 
                                        item.sku.substring(0, 17) + '...' : 
                                        item.sku}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
}

export default IncomingItems;